<template>
  <div class="main">
    <div class="back">
      <div @click="topath('/index')">分会管理</div>
      <div>
        <img
          src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92%E5%BD%A2.svg"
          alt=""
        />
      </div>
      <div @click="topath('/index')">分会活动表</div>
      <div>
        <img
          src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92%E5%BD%A2.svg"
          alt=""
        />
      </div>
      <div @click="topath('/hotlist')">活动列表</div>
      <div>
        <img
          src="https://bunseninstitute.oss-cn-hangzhou.aliyuncs.com/files/images/%E4%B8%89%E8%A7%92%E5%BD%A2.svg"
          alt=""
        />
      </div>
    </div>
    <div class="thetitle">分会活动表</div>
    <div class="profile">
      <div class="title"><div class="infor">编辑活动</div></div>
      <div class="titlerst">
        <div class="infor violet">
          <span>注：</span
          ><span>对于往年活动，您只能进行查看，不可以编辑修改。</span>
        </div>
      </div>
      <div class="row">
        <div class="titler">活动名称：</div>
        <div class="input phone">
          <el-input v-model="lastname.name" placeholder=""></el-input>
        </div>
      </div>
      <div class="row">
        <div class="titler">主办机构：</div>
        <div class="input">
          <el-input v-model="lastname.position" placeholder=""></el-input>
        </div>
      </div>

      <div class="row">
        <div class="nation">
          <div class="titler">活动开始日期：</div>
          <div class="input">
            <a-date-picker
              size="large"
              v-model="lastname.date"
              placeholder="请选择日期"
            />
          </div>
        </div>
        <div class="city">
          <div class="titler">活动结束日期：</div>
          <div class="input">
            <a-date-picker
              size="large"
              v-model="lastname.date"
              placeholder="请选择日期"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="nation">
          <div class="titler">活动所在国家：</div>
          <div class="input">
            <el-input v-model="lastname.email" placeholder=""></el-input>
          </div>
        </div>
        <div class="city">
          <div class="titler">活动所在省份：</div>
          <div class="input">
            <el-input v-model="lastname.position" placeholder=""></el-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="nation">
          <div class="titler">活动所在城市：</div>
          <div class="input">
            <el-input v-model="lastname.email" placeholder=""></el-input>
          </div>
        </div>
        <div class="city">
          <div class="titler">邮编：</div>
          <div class="input">
            <el-input v-model="lastname.position" placeholder=""></el-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="titler">活动详细地址：</div>
        <div class="input phone">
          <el-input v-model="lastname.name" placeholder=""></el-input>
        </div>
      </div>
      <div class="row">
        <div class="nation">
          <div class="titler">联系人姓名：</div>
          <div class="input">
            <el-input v-model="lastname.email" placeholder=""></el-input>
          </div>
        </div>
        <div class="city">
          <div class="titler">联系人邮箱：</div>
          <div class="input">
            <el-input v-model="lastname.position" placeholder=""></el-input>
          </div>
        </div>
      </div>
      <div class="row start">
        <div class="titler">活动类型：</div>
        <div class="input phone">
          <el-checkbox-group
            v-model="checkedCities"
            @change="handleCheckedCitiesChange"
          >
            <el-checkbox v-for="city in cities" :key="city" :label="city">{{
              city
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="row start">
        <div class="titler">活动说明：</div>
        <div class="input phone">
          <el-input v-model="lastname.desc" type="textarea" />
        </div>
      </div>
      <div class="row">
        <div class="titler">活动简要概括：</div>
        <div class="input">
          <el-input v-model="lastname.position" placeholder=""></el-input>
        </div>
      </div>
      <div class="row margin40">
        <div class="titler">活动出席人数：</div>
        <div class="input">
          <el-input v-model="lastname.email" placeholder=""></el-input>
          <div class="hint">(请输入数字即可)</div>
        </div>
      </div>
      <div class="row">
        <div class="nation">
          <div class="titler">普通门票费用：</div>
          <div class="input">
            <el-input v-model="lastname.email" placeholder=""></el-input>
          </div>
        </div>
        <div class="city">
          <div class="titler">分会开销：</div>
          <div class="input">
            <el-input v-model="lastname.position" placeholder=""></el-input>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="titler"></div>
        <div class="input Continue">
          <el-checkbox v-model:checked="continueadd" @change="handupdate()" />
          <div class="">
            与联系人相同<span class="lineinfo"
              >(若提交人与联系人相同，勾选此项则不再需要填写下面两项内容)</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="nation">
          <div class="titler">提交人姓名：</div>
          <div class="input">
            <el-input v-model="lastname.email" placeholder=""></el-input>
          </div>
        </div>
        <div class="city">
          <div class="titler">提交人邮箱：</div>
          <div class="input">
            <el-input v-model="lastname.position" placeholder=""></el-input>
          </div>
        </div>
      </div>
      <div class="save hoverbottom">保存</div>
    </div>
  </div>
</template>
        
    <style lang="scss">
.el-textarea__inner {
  height: 152px;
  border: 2px solid #777777 !important;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #d15243;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
  background: #d15243;
  border-color: #c10a74;
  border: 0px !important;
}
.el-checkbox__inner {
  width: 24px !important;
  height: 24px !important;
  border-radius: 4px;
  border: 2px solid #777777 !important;
  &::after {
    width: 6.71px !important;
    height: 12.2px !important;
    border: 4px solid transparent;
    border-left: 0;
    border-top: 0;
    left: 7px;
    top: 3px;
  }
}
.el-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  .el-checkbox {
    width: 33.3%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
}
.ant-picker-large {
  width: 100%;
  height: 64px;
  border: 2px solid #777777 !important;
  padding: 20px 20px !important;
  box-sizing: border-box;
  .anticon svg {
    width: 22px !important;
    height: 22px !important;
  }
}
.el-input__wrapper {
  height: 64px;
  box-sizing: border-box;
  padding: 20px 20px !important;
  border: 2px solid #777777 !important;
  border-radius: 8px !important;
}
</style>
    <script>
export default {
  name: "Home",
  data() {
    return {
      checkedCities: [],
      continueadd: false,
      cities: [
        "IADGE庆典",
        "社区服务/外联",
        "与工作相关",
        "讲座/技术讲座",
        "筛查",
        "社会事件",
        "车间",
        "会议或研讨会",
        "分会会议/选举会议",
        "筹款人",
        "联席会议",
        "面板",
        "研讨课",
        "学生竞赛",
        "会议或研讨会嘉宾非赞助商",
        "其他",
        "私人活动(此活动不会发布到活动列表中)",
      ],
      lastname: {
        name: "",
        email: "",
        position: "",
        street: "",
        Other_address: "",
        nation: "",
        city: "",
        membership: "",
        officers: "",
        date: "",
        desc: "",
      },
    };
  },
  methods: {
    handleCheckedCitiesChange(e) {
      console.log(e);
    },
    topath(e) {
      console.log(e, 12345);
      this.$router.push(e);
    },
    handupdate() {},
  },
  components: {},
};
</script>
        <style scoped lang="scss">
.main {
  padding: 100px 0px;
  max-width: 1264px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .profile {
    padding: 56px 60px;
    border: 2px solid #333333;
    border-radius: 40px 0 0 0;
    width: 100%;
    box-sizing: border-box;
    color: #333;
    .save {
      width: 364px;
      margin: 0 auto;
      text-align: center;
      height: 58px;
      background: #c81677;
      border-radius: 50px 50px 50px 50px;
      line-height: 58px;
      font-size: 20px;
      font-weight: 400;
      color: #ffffff;
      margin-top: 60px;

      cursor: pointer;
    }

    .row {
      display: flex;
      margin-bottom: 20px;
      align-items: center;

      .nation {
        display: flex;
        align-items: center;
        flex: 1;
      }
      .city {
        display: flex;
        align-items: center;
        flex: 1;
      }
      //   :nth-child(1) {
      //     font-size: 18px;
      //     font-weight: bold;
      //     color: #333333;
      //     line-height: 21px;
      //     width: 126px;
      //     text-align: right;
      //   }
      .titler {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        line-height: 21px;
        width: 163px;
        text-align: right;
      }
      .left20 {
        margin-left: 20px;
      }
      .Continue {
        display: flex;
        align-items: center;
        div {
          font-size: 18px;
          font-weight: 400;
          color: #333333;
          line-height: 21px;
          margin-left: 20px;
          .lineinfo {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            // line-height: 21px;
          }
        }
      }
      .input {
        margin-left: 20px;
        // margin-bottom: 40px;
        // width: 100%;
        flex: 1;
        position: relative;

        .hint {
          font-size: 12px;
          font-weight: 400;
          color: #333333;
          line-height: 14px;
          margin-top: 4px;
          position: absolute;
          margin-bottom: 20px;
          // margin-left: 20px;
        }
        .hintsize {
          display: flex;
          flex-direction: column;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          :nth-child(2) {
            margin-top: 4px;
          }
        }
        // }
      }
    }
    .start {
      align-items: flex-start;
    }
    .margin40 {
      margin-bottom: 40px;
    }
    .theaddress {
      margin-top: 40px;
    }
    .titlerst {
      margin-bottom: 32px;
      font-size: 18px;
      font-weight: bold;
      color: #d95347;
      line-height: 21px;
      :nth-child(2) {
        font-weight: 400;
      }
    }
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #000000;
      line-height: 28px;
      position: relative;
      margin-bottom: 32px;
      .infor {
        display: flex;
        align-items: center;
        &::before {
          content: "";
          width: 6px;
          height: 24px;
          background: #c81677;
          position: absolute;
          left: -10px;
        }
      }
    }
  }
  .back {
    position: absolute;
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    top: 20px;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      width: 17px;
      transform: rotate(180deg);
      margin: 0 6px;
    }
    div:hover {
      text-decoration: underline;
      color: #c81677;
    }
  }
}
</style>
        